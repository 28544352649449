export default function Footer() {

    return (
        <div className="contact-container" style={{ position: 'fixed', bottom: '0px', width: '100%', zIndex: '999' }}>
            <footer className="footer py-2 md:py-3 lg:px-4">
                <div className="footer-content pl-4">
                    <div className="footer-logo" style={{ zIndex: '9999' }}>
                        <a href="/" style={{ height: '100%' }}>
                            <img src="images/logo-footer.webp" alt="Footer Logo" />
                        </a>
                    </div>
                    <div className="footer-links text-xs md:text-base" style={{ zIndex: '9999' }}>
                        {/* <a href="/services">Services</a>
                    <a href="/reservation">Réservation</a> */}
                        <a href="/contact">Contact</a>
                    </div>
                </div>
            </footer>
            {/* <div className='shadowBox' style={{ zIndex: '1' }} /> */}
        </div>)
}
import React from "react";


function Contact() {
    return (
        <div className="body">
            <img src="images/bg-4.webp" alt="Interior of Car" className="imageHeader" />
            <div className="contact-container" style={{ margin: "100px 0px 0px 0px" }}>
                <h2 className='text-2xl md:text-3xl font-bold text-gray-50 uppercase underline underline-offset-4' style={{ textAlign: "center", marginBottom: '25px', textDecorationColor: '#B5121B' }}>Contactez-Nous</h2>
                <div className="contact-panel text-gray-300">
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
                    <br />
                    <a className="contact-details" href="tel: 0771072309">
                        <span className="material-symbols-outlined">
                            call
                        </span>
                        0771072309
                    </a>
                    <a className="contact-details" href="mailto: contact@lkreprogrammation.fr">
                        <span className="material-symbols-outlined">
                            mail
                        </span>
                        contact@lkreprogrammation.fr
                    </a>
                    <a className="contact-details" href="https://www.facebook.com/profile.php?id=61550872804595">
                        <span className="material-symbols-outlined">
                            public
                        </span>
                        Facebook
                    </a>
                </div>
            </div>
        </div >)

}

export default Contact;